import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import jsPDF from 'jspdf'
import uuidv4 from 'uuid/v4'
import Input from '../components/Input'
import ScrollBar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'static/style/style.css'
import { AlertModal } from 'components/ModalTypes'
import * as userActions from '../store/actions/user'
import axios from 'axios'
import FileSaver from 'file-saver'
import Loading from '../components/Loading'
import classnames from 'classnames'
import authCodeTextImg from '../static/img/auth-code-text.png'


const API_URL = process.env.REACT_APP_API_URL

class ConsentComponent extends Component {
    state = {
      step: 1,
      consent: null,
      authCode: '',
      genAuthCode: uuidv4(),
      cancelModal: false,
      cancelled: false,
      loading: false,
      e_consent_settings: false,
    }

    componentDidMount() {
      const {
        match: {
          params: {
            token,
          },
        },
        userActions,
      } = this.props
      userActions.checkConsumer(token)
        .then(({ data: { step, e_consent_settings } }) => this.setState({ step, e_consent_settings }))
    }

    downloadFile = (url) => {
      const fullUrl = `${API_URL}${url}`
      this.setState({ loading: true })
      axios({
        method: 'GET',
        url: fullUrl,
        responseType: 'blob', // important
      }).then((response) => {
        FileSaver.saveAs(new Blob([response.data]), 'appraisal_report.pdf')
        this.setState({ loading: false, step: 3 })
      })
    }

    onConsent = () => {
      const {
        consent,
      } = this.state
      const {
        match: {
          params: {
            token,
          },
        },
        userActions,
      } = this.props

      if (!consent) {
        return this.setState({ cancelModal: true })
      }
      userActions.userConsent(token)
        .then(_ => this.setState({ step: 2 }))
        .catch(error => console.log(error))
    }

    onRadioChange = ({ target: { value } }) => this.setState({ consent: (/true/i).test(value) })

    onAuthCodeChange = (authCode) => this.setState({ authCode: authCode.trim() })

    onCancel = () => {
      const {
        match: {
          params: {
            token,
          },
        },
        userActions,
      } = this.props

      userActions.denyConsent(token)
        .then(_ => this.setState({ cancelled: true, cancelModal: false }))
    }

    downloadPDF = () => {
      const {
        genAuthCode,
      } = this.state

      const img = new Image()
      img.src = authCodeTextImg
      img.onload = function() {
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF()

        pdf.addImage(img, 'PNG', 20, 40, 129, 43)
        pdf.text(20, 90, `\t${genAuthCode}`)
        // save the PDF document (downloadable)
        pdf.save()
      }
    }

    render = () => {
      const {
        step,
        consent,
        authCode,
        genAuthCode,
        cancelModal,
        cancelled,
        e_consent_settings: eConsentSettings,
      } = this.state
      const {
        match: {
          params: {
            token,
          },
        },
      } = this.props

      if (cancelled) {
        return (
                <div>
                    <div className="container text-center">
                        <div className="success">
                            <div className="icon icon-cell animate">done</div>
                        </div>
                        <h1>Electronic Delivery Denied</h1>
                        <p className="info">
                            Your lender will be notified<br/>
                            Your appraisal will be sent to you in the Mail
                        </p>
                        <p className="info">To restart the process click the button below.</p>
                        <button className="button" onClick={() => this.setState({ step: 1, cancelled: false })}>
                            Return to Electronic Consent Form
                        </button>
                    </div>
                </div>
        )
      }

      const isValidAuthCode = authCode === genAuthCode

      return (
            <div>
                <div className="container text-center">
                    {cancelModal && (
                        <AlertModal
                            closeText="No"
                            submitText="Yes"
                            onClose={() => this.setState({ cancelModal: false })}
                            onSubmit={this.onCancel}
                        >
                            <div>Are you sure you want to deny Electronic Delivery?</div>
                        </AlertModal>
                    )}
                    {this.state.loading && <Loading/>}
                    {step === 0 && (
                        <div className="box bg">
                            <h1>This link was sent in error.</h1>
                            <p>Please disregard the email.</p>
                        </div>
                    )}

                    {step === 1 && (
                        <div className="box bg">
                            <h1>Acknowledge Electronic Delivery</h1>
                            <ScrollBar className="scrollable-text">
                                <p>
                                    In a few moments you’ll be able to download an electronic (Adobe Portable Document
                                    Format, or PDF) copy of the appraisal report associated with your loan application.
                                    First, you’ll need an authorization code. But even before that, we want to make sure
                                    that you understand the following.
                                </p>
                                <br/>
                                <p>
                                    By completing the steps below, you’re consenting to receive the appraisal report
                                    obtained for your loan application in electronic (PDF) format instead of a paper
                                    copy. And you’re acknowledging the following:
                                </p>
                                <br/>
                                <li>
                                    You will not be charged for this electronic copy of the appraisal report or
                                    subsequent electronic copies of this appraisal report you download from us.
                                </li>
                                <br/>
                                <li>
                                    You can instead receive a paper copy of the appraisal report rather than this
                                    electronic copy by not completing our process and contacting your loan officer or
                                    loan representative. If you choose a paper copy instead, you will not be charged for
                                    the initial paper copy of the appraisal report you obtain from your loan officer. If
                                    you decide to obtain this electronic copy, you can still request an extra paper copy
                                    of the appraisal report from your lender after you receive this electronic copy. But
                                    please note that there may be a fee involved in sending a paper copy of the
                                    appraisal report after you’ve already received an electronic copy.
                                </li>
                                <br/>
                                <li>
                                    You can withdraw this consent at any time at no cost to you by contacting your loan
                                    officer or loan representative. Your withdrawal of consent will be effective
                                    immediately.

                                    Your withdrawal of consent will terminate your ability to obtain an electronic copy
                                    of the appraisal report from us.

                                </li>
                                <br/>
                                <li>
                                    Your consent applies only to the electronic delivery of this appraisal report. Your
                                    mortgage lender or mortgage representative will provide you with any disclosures or
                                    other documents that may be associated with your appraisal. Your consent doesn’t
                                    apply to other files or documents related to your loan application, other subsequent
                                    valuations that may be obtained by the lender in connection with your loan
                                    application, or other loan applications or transactions.
                                </li>
                                <br/>
                                <li>
                                    If you have submitted a joint application, you are the primary borrower and are
                                    authorized to accept the electronic delivery of the appraisal report on behalf of
                                    the other borrowers.
                                </li>
                                <br/>
                                <li>
                                    You can update your contact information, including your email address, by contacting
                                    your loan officer or loan representative.
                                </li>
                                <br/>
                                <li>
                                    Your computer or other device meets the hardware and software requirements, as
                                    described below, to enable delivery, access to, and retention of the appraisal
                                    report.
                                </li>

                                <br/>
                                <p>
                                    In order to enable successful and complete electronic delivery, access to, and
                                    retention of the appraisal report, your computer or device must meet certain
                                    hardware and software requirements. You’ll need an active email account, an Internet
                                    connection, any device (e.g., computer desktop, laptop, mobile device) using
                                    operating systems and internet browsers that support viewing Portable Document
                                    Format (PDF) with a PDF viewer, such as Adobe Reader©, that can open and read PDF
                                    files. You can obtain a free copy of Adobe Reader software here:
                                    https://www.adobe.com/acrobat/pdf-reader.html.
                                </p>
                                <br/>
                                <p>
                                    If you click “I consent to receive the appraisal report in electronic form” below
                                    and click “Continue,” this means that you have consented to receive an electronic
                                    copy of your appraisal report. To confirm your consent, we will then need to make
                                    sure your computer or device is able to open the electronic (PDF) copy of the
                                    appraisal report. To do this, we’re going to give you an authorization code to enter
                                    before you can access the appraisal report. To get your authorization code, here’s
                                    what you do. Click the button ‘Download Auth Code’. When you do, an electronic file
                                    in PDF format with your authorization code on it should open or be saved to your
                                    computer or device. (You may be given a choice to either ‘Open’ or ‘Save’ the file.
                                    If you are given a choice, choose ‘Open’.) If it downloads without opening, go to
                                    the folder on your computer or device where the file is saved, and open the file
                                    from there.
                                </p>
                                <br/>
                                <p>
                                    If the file opens and you can see your authorization code — great! That means your
                                    computer or device is able to open the electronic (PDF) copy of the appraisal
                                    report, too. Enter the authorization code where requested on the same page and click
                                    ‘”Download Appraisal” to obtain, download and/or print an electronic copy of your
                                    appraisal report. Of course, if you change your mind about receiving an electronic
                                    copy of your appraisal report, on this very same page, you may click “Deny” and
                                    refuse to provide consent to electronic delivery. Just make sure to let your loan
                                    officer or loan representative know that you’ve withdrawn from the electronic
                                    process so that they may provide you with a paper copy of the appraisal report.
                                </p>
                                <br/>
                                <p>
                                    Unless you subsequently withdraw your consent to receiving an electronic copy of
                                    your appraisal report, you may access and download subsequent electronic copies of
                                    the appraisal for up to 90 days. There is no guarantee the report will be available
                                    after 90 days from the date you give your consent.
                                </p>
                                <br/>
                                <p>
                                    Finally, if you have any trouble getting the authorization code or accessing or
                                    opening the appraisal report, please contact your loan officer or loan
                                    representative.
                                </p>
                            </ScrollBar>
                            <form className="radio-form">
                                <div className="radio-line">
                                    <input
                                        className="radio"
                                        type="radio"
                                        name="consent"
                                        value={true}
                                        onChange={this.onRadioChange}
                                    />
                                    <span>
                        I consent to receive the appraisal report in electronic
                        form
                      </span>
                                </div>
                                <div className="radio-line">
                                    <input
                                        className="radio"
                                        type="radio"
                                        name="consent"
                                        value={false}
                                        onChange={this.onRadioChange}
                                    />
                                    <span>
                        I decline to receive the appraisal report electronically
                      </span>
                                </div>
                            </form>
                            <div>
                                <button
                                    className="button no-transform error"
                                    onClick={() => this.setState({ cancelModal: true })}
                                >
                                    Deny
                                </button>
                                <button
                                    disabled={consent === null}
                                    className={'button' + (consent === null ? '-disabled' : '')}
                                    onClick={this.onConsent}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    )}
                    {step === 2 && (
                        <div className="box bg">
                            <h1>Download Your Appraisal</h1>
                            <p className="info">
                                Complete the steps below to download your appraisal.
                            </p>
                            <p className="body-text">
                                Step 1*: Download the test PDF to obtain your security code.
                            </p>
                            <button className="button no-transform" onClick={this.downloadPDF}>
                                Download Test PDF
                            </button>
                            <p className="body-text">
                                Step 2*: Enter the security code below.
                            </p>
                            <Input
                                input={{ type: 'text', placeholder: 'Enter Auth Code' }}
                                onChange={this.onAuthCodeChange}
                                field="authCode"
                                value={authCode}
                            />
                            <p className="body-text">
                                Step 3*: You are all set! Download your appraisal by clicking
                                the button below.{' '}
                            </p>
                            <button
                                disabled={!isValidAuthCode}
                                className={classnames(
                                  'no-transform',
                                  {
                                    button: isValidAuthCode,
                                    'button-disabled': !isValidAuthCode,
                                  }
                                )}
                                onClick={() =>
                                  this.downloadFile(`/order/submission/${token}`)
                                }
                            >
                                Download Appraisal
                            </button>
                            {eConsentSettings && <div>
                                <p className="body-text">
                                    If you do not wish to grant consent, you may deny consent.
                                </p>
                                <button
                                    className="button no-transform error"
                                    onClick={() => this.setState({ cancelModal: true })}
                                >
                                    Deny
                                </button>
                            </div>}

                            <div className="requirement-text-container">
                                <p className="caption">
                                    *Required to successfully download appraisal
                                </p>
                            </div>
                        </div>
                    )}

                    {step === 3 && (
                        <div className="container">
                            <div className="success">
                                <div className="icon icon-cell animate">done</div>
                            </div>
                            <h1>Appraisal Downloaded!</h1>
                            <p className="info">
                                Your appraisal has been successfully downloaded
                                <br/>
                                Thank you for using Reggora
                            </p>
                        </div>
                    )}
                    {step === 4 && (
                        <div className="box bg">
                            <h1>Welcome Back!</h1>
                            <p className="info">
                                You have already downloaded your appraisal
                                <br/>
                                But you can download it again by clicking the button below
                            </p>
                            <button
                                className="button"
                                onClick={() =>
                                  this.downloadFile(`/order/submission/${token}`)
                                }
                            >
                                Download Appraisal
                            </button>
                        </div>
                    )}
                    {step === 5 && (
                        <div className="box bg">
                            <h1>Welcome Back!</h1>
                            <p className="info">
                                You denied to electronically download your appraisal
                                <br/>
                                But you can change your mind at any time.
                            </p>
                            <p className="info">
                                To restart the process click the button below.
                            </p>
                            <button
                                className="button"
                                onClick={() => this.setState({ step: 1 })}
                            >
                                Return to Electronic Consent Form
                            </button>
                        </div>
                    )}
                </div>
            </div>
      )
    }
}

const mapDispatchToProps = dispatch => ({
  userActions: bindActionCreators(userActions, dispatch),
})

export default connect(null, mapDispatchToProps)(ConsentComponent)
